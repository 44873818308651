import { useEffect, useState } from 'react';
import { fetchSubmission } from '../services/submissionService';

export default function useSubmission(submissionId) {
  const [questions, setQuestions] = useState([]);
  const [docSnippets, setDocSnippets] = useState('');
  const [aiInfo, setAiInfo] = useState([]);
  const [keywordsInfo, setKeywordsInfo] = useState([]);
  const [learningScore, setLearningScore] = useState([]);
  const [stylometricScore, setStylometricScore] = useState([]);
  const [answerScore, setAnswerScore] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [transcription, setTranscription] = useState('');
  const [studentName, setStudentName] = useState('');
  const [assignmentName, setAssignmentName] = useState('');
  const [courseName, setCourseName] = useState('');

  useEffect(() => {
    async function fetchData() {
      const data = await fetchSubmission(submissionId);
      if (data) {
        console.log(data)
        setVideoUrl(data.videoUrl);
        setQuestions(data.questions || []);
        setDocSnippets(data.docSnippets);
        setAiInfo(data.textAiDetection.ai_det_response)
        setLearningScore(data.learningScore[0])
        setKeywordsInfo(data.keywordsDetection)
        setStylometricScore(data.stylometricScore)
        setAnswerScore(data.labelled)

        // setDocUrl(data.docUrl);
        // setDocType(data.docS3Key.split('.')[1]);
        // setDocType(data.docS3Key.split('.')[1]);
        // setTranscription(data.transcription.segments || []);

        setTranscription(data.transcription || []);
        setStudentName(data.studentName)
        setAssignmentName(data.assignmentName)
        setCourseName(data.courseName)
      }
    }
    fetchData();
  }, [submissionId]);

  return { questions, docSnippets, aiInfo,keywordsInfo,learningScore,stylometricScore,answerScore, videoUrl, transcription, studentName, assignmentName, courseName };
}
