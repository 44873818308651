export async function markarchive(submissionId) {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/submission/archive`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ submission_ids: submissionId }),
        });

        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.log(error);
    }
}

export async function markunarchive(submissionId) {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/submission/unarchive`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ submission_ids: submissionId }),
        });

        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.log(error);
    }
}

export async function markdeleted(submissionId) {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/submission/deleted`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ submission_ids: submissionId }),
        });

        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.log(error);
    }
}

export async function fetchSubmissions(userid) {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/submission/teacher`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ teacher_id: userid }),
        });

        const data = await response.json();
        return data
    } catch (error) {
        console.log(error);
    }
    return null
}

export async function fetchArchived(userid) {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/submission/fetcharchived`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ teacher_id: userid }),
        });

        const data = await response.json();
        return data
    } catch (error) {
        console.log(error);
    }
    return null
}

export async function fetchAssignmentSubmission(userid, assignmentId) {
    console.log(assignmentId)
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/submission/fetchassignmentsubmission`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ teacher_id: userid, assignment_id: assignmentId }),
        });

        const data = await response.json();
        return data
    } catch (error) {
        console.log(error);
    }
    return null
}

export async function fetchOverview(assignmentId, setLoadingOverview) {
    setLoadingOverview(true)
    try {
      // Fetch submissions for course
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/submission/course/all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ assignment_id: assignmentId }),
      });

      if (response.ok) {
        const data = await response.json();
        setLoadingOverview(false)
        return data
      } 
        console.log('Failed to fetch students');
    } catch (error) {
      console.log(error);
    }
    return null
  }
