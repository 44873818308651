import { Box } from '@mui/material';
import ReactSpeedometer from "react-d3-speedometer"

export default function MemorizationScore({memorizationScore}) {
    return (
        <Box>
            {Object.entries(memorizationScore).filter(([key, value]) => key === 'rouge1').map(([key, value], index) => {
                    const score = value * 100;
                    return (
                        <Box key={index} sx={{mb:1}}>
                            <ReactSpeedometer
                                needleHeightRatio={0.7}
                                value={score}
                                maxValue={100}
                                currentValueText="Memorization"
                                textColor="black"
                                height={200}
                                customSegmentLabels={[
                                {
                                    text: 'None',
                                },
                                {
                                    text: 'Low',
                                },
                                {
                                    text: 'Moderate',
                                },
                                {
                                    text: 'High',
                                },
                                {
                                    text: 'Full',
                                }
                                ]}
                                startColor="#33CC33"
                                endColor="red"
                                ringWidth={47}
                                needleTransitionDuration={3333}
                                needleTransition="easeElastic"
                                needleColor={'grey'}
                            />
                        </Box>

                    );
                })}
        </Box>

    );
}
