import { Helmet } from 'react-helmet-async';
import {
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { Submission } from '../components/tableview';
import { fetchArchived } from '../sections/teacher/services/teacherservices';

export default function ArchivedPage({ userid }) {
  return (
    <>
      <Helmet>
        <title> Archived</title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 5, mt: 5 }}>
          <Typography variant="h4">Archive</Typography>
        </Stack>
        <Submission userid={userid} fetchSubmissionData={fetchArchived} state={0}/>
        </Container>
    </>
  );
}
