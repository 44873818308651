import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import Logo from '../components/logo';
import { StyledSection, StyledContent, useAuthentication } from '../sections/login';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function LoginPage() {
  const { navigateToSignUpPage, isAuthenticated, navigateToHomePage } = useAuthentication();

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
        <StyledSection />
        <StyledContent
          navigateToSignUpPage={navigateToSignUpPage}
          navigateToHomePage={navigateToHomePage}
          isAuthenticated={isAuthenticated}
        />
      </StyledRoot>
    </>
  );
}
