import { Helmet } from 'react-helmet-async';
import {
  Container,
  Stack,
  Typography,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchSubmissions } from '../sections/teacher/services/teacherservices';
import { Submission } from '../components/tableview';

export default function TeacherPage({ userid }) {
  const navigate = useNavigate()
  const navigateToArchive = () => {
    navigate(`/dashboard/archived`);
  };
  return (
    <>
      <Helmet>
        <title> Home</title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 5, mt: 5 }}>
          <Typography variant="h4">My Dashboard</Typography>
          <Button variant="contained" onClick={navigateToArchive}>
            Go to archive
          </Button>
        </Stack>

        <Submission fetchSubmissionData={fetchSubmissions} userid={userid} state={1}/>
      </Container>
    </>
  );
}
