import { TableCell } from '@mui/material';

export default function ScoreTableCell({ score }) {
  let backgroundColor;
  let textColor;
  const scoreDesc = {
    0: "NO UNDERSTANDING",
    1: "SOME UNDERSTANDING",
    2: "GOOD UNDERSTANDING",
  };
  if (score === 2) {
    backgroundColor = 'green';
    textColor = 'white';
  } else if (score === 1) {
    backgroundColor = '#de9104fc';
    textColor = 'white';
  } else {
    backgroundColor = 'red';
    textColor = 'white';
  }

  return (
    <TableCell align="left">
      <div
        style={{
          backgroundColor,
          borderRadius: '20px',
          width: '150px',
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign:'center',
          color: textColor,
        }}
      >
        {scoreDesc[score]}
      </div>
    </TableCell>
  );
}
