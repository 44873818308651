import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';

export default function StyledSection() {
  const mdUp = useResponsive('up', 'md');
  const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
  }));

  return (
    mdUp && (
      <StyledSection>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
          Hi, Welcome Back
        </Typography>
        <img src="/assets/illustrations/illustration_login.png" alt="login" />
      </StyledSection>
    )
  );
}
