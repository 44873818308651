import { Box, Typography, Rating, Card, CardContent,Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import StarIcon from '@mui/icons-material/Star';

const labels = {
    0: 'Poor',
    0.5: 'Poor',
    1: 'Poor',
    1.5: 'Poor',
    2: 'Poor',
    2.5: 'Ok',
    3: 'Ok',
    3.5: 'Good',
    4: 'Good',
    4.5: 'Excellent',
    5: 'Excellent',
};

const scoreScale = {
    0: 0,
    1: 0.5,
    2: 1,
  };
  

const learningScoreDesc = "We evaluates the relevance and accuracy of a student's response in comparison to the reference material with understanding and memorization assessment. It measures how well the student has grasped and internalized the subject matter"

export default function LearningScore({memorizationScore, answerScore}) {
    return (
        <Card variant="outlined">
            <CardContent>
                <Box sx={{ display: 'flex', gap:"5px" }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
                        Learning Score
                    </Typography>
                    <Tooltip title={learningScoreDesc} arrow>
                        <InfoIcon/>
                    </Tooltip>
                </Box>
                
                {Object.entries(memorizationScore).filter(([key, value]) => key === 'rouge1').map(([key, value], index) => {
                    const precision = 0.5;
                    const score = ((scoreScale[answerScore] - value) * 100)/2;
                    const scoreRoundedToPrecision = parseFloat((Math.round(score / precision) * precision).toFixed(1) / 10);
                    
                    return (
                        <Box key={index} sx={{mb:1}}>
                            {/* <Typography>
                                {key}
                            </Typography> */}
                            <Box
                                sx={{
                                    width: 200,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Rating
                                    value={scoreRoundedToPrecision}
                                    readOnly
                                    precision={precision}
                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                />
                                <Box sx={{ ml: 2 }}>{labels[scoreRoundedToPrecision]}</Box>
                            </Box>
                        </Box>

                    );
                })}
            </CardContent>
        </Card>

    );
}
