import ReactDOM from 'react-dom/client';
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { unregister } from './serviceWorker';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const redirectUri = process.env.REACT_APP_REDIRECT; 

root.render(
  <React.StrictMode>
    <Auth0Provider domain={domain} clientId={clientId} authorizationParams={{ redirect_uri: redirectUri }} useRefreshTokens
cacheLocation="localstorage">
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to enable client cache, register instead.
// serviceWorker.register();
unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
