import { useEffect, useState } from 'react';
import {
    Card,
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Iconify from '../iconify/Iconify';
import Scrollbar from '../scrollbar/Scrollbar';
import { UserListHead, UserListToolbar, ScoreTableCell } from '../../sections/teacher';
import { fetchOverview } from '../../sections/teacher/services/teacherservices';

const TABLE_HEAD = [
    { id: 'Name', label: 'Name', alignRight: false },
    { id: 'Course', label: 'Course', alignRight: false },
    { id: 'Assignment', label: 'Assignment', alignRight: false },
    { id: 'Score', label: 'Score', alignRight: false },
    { id: 'Date', label: 'Submission Date', alignRight: false },
    { id: '' },
];

function getComparator(order, orderBy) {
    if (orderBy === 'Score') {
        return order === 'desc'
            ? (a, b) => b.labelled - a.labelled
            : (a, b) => a.labelled - b.labelled;
    }

    if (orderBy === 'Date') {
        return order === 'asc'
            ? (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            : (a, b) => new Date(b.createdAt) - new Date(a.createdAt);
    }

    if (orderBy === 'Name') {
        return order === 'desc'
            ? (a, b) => b.studentName.localeCompare(a.studentName)
            : (a, b) => a.studentName.localeCompare(b.studentName);
    }

    if (orderBy === 'Assignment') {
        return order === 'desc'
            ? (a, b) => b.assignmentName.localeCompare(a.assignmentName)
            : (a, b) => a.assignmentName.localeCompare(b.assignmentName);
    }

    if (orderBy === 'Course') {
        return order === 'desc'
            ? (a, b) => b.courseName.localeCompare(a.courseName)
            : (a, b) => a.courseName.localeCompare(b.courseName);
    }

    return () => 0;
}

function applySortFilter(array, comparator, query) {
    let filteredArray = array;
    if (!array || !Array.isArray(array)) {
        return [];
    }

    if (query) {
        const searchFields = ['studentName', 'courseName', 'assignmentName'];
        filteredArray = array.filter((_user) =>
          searchFields.some((field) =>
            _user[field] && _user[field].toLowerCase().includes(query.toLowerCase())
          )
        );
      }      

    const stabilizedThis = filteredArray.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}

export default function Submission({ fetchSubmissionData, userid, state, assignmentId, overview, setOverview, setLoadingOverview, selectedProp, updateSelectedProp }) {
    // Initialize the selected state with a default value if selectedProp is not provided
    const [selected, setSelected] = useState(selectedProp || []);

    // Initialize the updateSelected function with a default implementation if not provided
    const updateSelected = updateSelectedProp || ((newSelected) => {
        setSelected(newSelected);
    });

    const [selectedStudents, setSelectedStudents] = useState([]); 

    const updateSelectedStudents = (evt) => {
        console.log(evt);
        setSelectedStudents([evt.target.value]);
    }

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('Date');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [submissionList, setsubmissionList] = useState([]);
    const [filterScore, setFilterScore] = useState('');
    const [filterDateRange, setFilterDateRange] = useState([null, null]);

    const navigate = useNavigate();

    const handleClearAll = () => {
        setFilterName('');
        setFilterDateRange([null, null]);
        setFilterScore('')
    }

    useEffect(() => {
        const fetchData = async () => {
            let data;
            // Retrieve submissions using assignmentId
            if (state === 2) {
                data = await fetchSubmissionData(userid, assignmentId);
            } else {
                data = await fetchSubmissionData(userid);
            }
            console.log(data);
            setsubmissionList(data);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userid]);


    const updateSubmissionList = async () => {
        let data;
        // Retrieve submissions using assignmentId
        if (state === 2) {
            data = await fetchSubmissionData(userid, assignmentId);
            overview = await fetchOverview(assignmentId, setLoadingOverview)
            setOverview(overview)
        } else {
            data = await fetchSubmissionData(userid);
        }
        console.log(data);
        setsubmissionList(data);
    };

    const handleFilterScore = (value) => {
        setFilterScore(value);
    };

    const handleFilterDateRange = (range) => {
        setFilterDateRange(range);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'desc';
        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleClick = (event, submissionId, studentId) => {
        const selectedIndex = selected.indexOf(submissionId);
        const newSelected = [...selected];
        const newSelectedStudents = [...selectedStudents];
    
        if (selectedIndex === -1) {
            newSelected.push(submissionId);
            newSelectedStudents.push(studentId);
        } else {
            newSelected.splice(selectedIndex, 1); 
    
            const studentIndex = newSelectedStudents.indexOf(studentId);
            if (studentIndex !== -1) {
                newSelectedStudents.splice(studentIndex, 1);
            }
        }
    
        updateSelected(newSelected);
        setSelectedStudents(newSelectedStudents);
    };    

    useEffect(() => {
        console.log(selectedStudents); // This will log when selectedStudents changes
    }, [selectedStudents]);
    
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = submissionList.map((n) => n._id);
            const students = submissionList.map((n) => n.studentId);
            setSelectedStudents(students);
            updateSelected(newSelecteds);
        } else {
            updateSelected([]);
            setSelectedStudents([]);
        }
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleView = (submissionId) => {
        const link = submissionId._id;
        navigate(`/dashboard/submission/${link}`);
    };

    const clearSelected = () => {
        updateSelected([]);
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - submissionList.length) : 0;

    const filteredUsers = applySortFilter(submissionList, getComparator(order, orderBy), filterName);
    const filteredByScore = filterScore
        ? filteredUsers.filter((user) => user.labelled === parseInt(filterScore, 10))
        : filteredUsers;

    const filteredByDateRange =
        filterDateRange[0] && filterDateRange[1]
            ? filteredByScore.filter((user) => {
                const userDate = new Date(user.createdAt).toISOString().split('T')[0];
                return userDate >= filterDateRange[0] && userDate <= filterDateRange[1];
            })
            : filteredByScore;

    const isNotFound = !filteredByDateRange.length && !!filterName && !filteredUsers.length && !filteredByScore.length;

    return (
        <>
            <Card sx={{ width: '100%' }}>
                <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    filterScore={filterScore}
                    onFilterScore={handleFilterScore}
                    filterDateRange={filterDateRange}
                    onFilterDateRange={handleFilterDateRange}
                    handleClearAll={handleClearAll}
                    selected={selected}
                    setSelected={updateSelected}
                    clearSelected={clearSelected}
                    updateSubmissionList={updateSubmissionList}
                    state={state}
                />

                <Scrollbar>
                    <TableContainer sx={{ width: '100%' }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={submissionList ? submissionList.length : 0}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filteredByDateRange.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { studentId, studentName, courseName, labelled, assignmentName, createdAt, _id } = row;
                                    const date = new Date(createdAt);
                                    const localDt = date.toLocaleString(undefined, {
                                        dateStyle: 'medium',
                                        timeStyle: 'short',
                                    });

                                    const selectedUser = selected.indexOf(_id) !== -1;

                                    return (
                                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                            <TableCell padding="checkbox">
                                                <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id, studentId)} />
                                            </TableCell>
                                            <TableCell align="left">{studentName}</TableCell>
                                            <TableCell align="left">{courseName}</TableCell>
                                            <TableCell align="left">{assignmentName}</TableCell>
                                            <ScoreTableCell score={labelled} />
                                            <TableCell align="left">{localDt}</TableCell>
                                            <TableCell align="left">
                                                <IconButton size="large" color="inherit" onClick={() => handleView({ _id })}>
                                                    <Iconify icon={'eva:eye-fill'} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 10 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>

                            {isNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <Paper
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography variant="h6" paragraph>
                                                    Not found
                                                </Typography>

                                                <Typography variant="body2">
                                                    No results found for &nbsp;
                                                    <strong>&quot;{filterName}&quot;</strong>.
                                                    <br /> Try checking for typos or using complete words.
                                                </Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    component="div"
                    count={submissionList ? submissionList.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
        </>
    )
}