import React, { useState, useEffect } from 'react';
import { Box,Card,CardContent,Stack,Chip,Typography } from '@mui/material';

export default function KeywordsMatching({keywordsInfo,updateSelectedKeywordsProp}) {
    const [keywordScore, setKeywordScore] = useState(0);
    const [matchingPairs, setMatchingPairs] = useState([]);
    const [chipsColor, setChipsColor] = useState([]);
    // Initialize the updateSelected function with a default implementation if not provided
    const updateSelectedKeywords = updateSelectedKeywordsProp;
    const defaultChipColor = "rgba(145, 158, 171, 0.16)"

    function generateRandomColorValues(){
      return Math.floor(Math.random() * 200) + 55;
    }

    const handleClick = (index, chipColor) => {
      const selectedChip = [];
      const chipArr = matchingPairs.map((value) => {
        if(value.key === index){
          value.color = value.color === defaultChipColor ? chipColor : defaultChipColor;
        }
        if(value.color !== defaultChipColor){
          selectedChip.push(value)
        }
        return value;
      });
      setMatchingPairs(chipArr)
      updateSelectedKeywords(selectedChip)
    };

    useEffect(() => {
        if(Object.keys(keywordsInfo).length > 0){
            setKeywordScore(Math.round(keywordsInfo.km_score * 100))
            const chipArr = []
            const colorArr = []
            keywordsInfo.match_pairs.forEach((value,index) => {
                const r = generateRandomColorValues();
                const g = generateRandomColorValues();
                const b = generateRandomColorValues();
                const rgbColor = `rgb(${r}, ${g}, ${b})`;
                colorArr[index] = rgbColor;
                chipArr.push({ key: index, label: value, color:defaultChipColor })  
            })
            setMatchingPairs(chipArr)
            setChipsColor(colorArr)
        }
    }, [keywordsInfo]);


  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
            <Typography variant="body1">
                <strong>Keywords Relevance:</strong> {keywordScore}%
            </Typography>
            {matchingPairs.length > 0 && (
            <Box>
            <Typography>
                  List of keywords:
              </Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap  justifyContent="space-between">
                {matchingPairs.map((data, index) => {
                    return (
                      <Chip
                      key={index}
                      label={data.label}
                      style={{backgroundColor:data.color}}
                      onClick={ () => handleClick(index, chipsColor[index]) }
                      />
                    );
                })}
              </Stack>
            </Box>
          )}
          
            
        </CardContent>
      </Card>
    </Box>
  );
}
