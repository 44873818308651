import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';

const ScatterPlot3d = ({ dataPoints,selectedStudents }) => {
  const [plotData, setPlotData] = useState([]);
  const studentIdToColor = {};
  const defaultColorToUse = "rgba(10, 10, 10, 0.3)"

  function generateRandomColorValues(){
    return Math.floor(Math.random() * 200) + 55;
  }

  useEffect(() => {
      if (dataPoints) {
        const dataArr = [];
        Object.keys(dataPoints).forEach((key) => {
          const xData = [];
          const yData = [];
          const zData = [];
          const values = dataPoints[key]
          let traceName = "";
          const studentId = key;
          values.forEach((value) =>{
            const studentCoordinates = value.coord;
            const studentName = value.studentName;
            traceName = `${studentName}(${studentId})`;
            if (studentCoordinates) {
              const [x, y, z] = studentCoordinates;
              xData.push(x);
              yData.push(y);
              zData.push(z);
            }
          });
          
          
          if (!(studentId in studentIdToColor)){
            const r = generateRandomColorValues();
            const g = generateRandomColorValues();
            const b = generateRandomColorValues();
            const rgbColor = `rgb(${r}, ${g}, ${b})`;
            studentIdToColor[studentId] = rgbColor;
          }
          const trace = {
            x: xData,
            y: yData,
            z: zData,
            name: traceName,
            text: studentId,
            type: 'scatter3d',
            mode: 'markers',
            marker: {
              size: 12,
              line: {
                color: 'rgba(217, 217, 217, 0.14)',
                width: 0.5,
              },
              opacity: 0.1,
              color: defaultColorToUse
            },
          }
          dataArr.push(trace)
      });
      setPlotData(dataArr);
    }   
  }, [dataPoints]);

  useEffect(() => {
    const traces = plotData.map((trace) => {
      let colorToUse = defaultColorToUse
      const studentId = trace.text
      if (selectedStudents.includes(studentId)){
        colorToUse = studentIdToColor[studentId] 
        trace.marker.opacity = 1
      }
      trace.marker.color = colorToUse
      return trace;
    });
    setPlotData(traces)
  }, [selectedStudents]);
  

  return (
    <Plot
      data={plotData}
      useResizeHandler
      layout={{
        height: '800',
        title: 'Stylometry clustering by seer.',
      }}
      config={{ responsive: true }}
    />
  );
};

export default ScatterPlot3d;
