import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { useEffect } from 'react';

const LoginButton = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const invitation = urlParams.get('invitation');
    const organization = urlParams.get('organization');
    const organizationName = urlParams.get('organization_name');

    if (invitation && organization && organizationName) {
      const redirectUri = `${process.env.REACT_APP_REDIRECT}`; 
      const authUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize` +
        `?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}` +
        `&redirect_uri=${encodeURIComponent(redirectUri)}` +
        '&response_type=code' +
        '&scope=openid profile email' +
        `&invitation=${invitation}` +
        `&organization=${organization}` +
        `&organization_name=${organizationName}`;

      // Redirect the user to the Auth0 /authorize endpoint with invitation parameters
      window.location.href = authUrl;
    }
  }, []);

  return (
    !isAuthenticated && (
      <Button fullWidth size="large" type="submit" variant="contained" onClick={() => loginWithRedirect()}>
        Login
      </Button>
    )
  );
};

export default LoginButton;
