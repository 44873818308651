import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export default function useAuthentication() {
  const { isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();

  const navigateToSignUpPage = () => {
    navigate('/signup');
  };

  const navigateToHomePage = () => {
    navigate('/dashboard/home');
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigateToHomePage();
    }
  }, [isAuthenticated, user]); // eslint-disable-line react-hooks/exhaustive-deps

  return { navigateToSignUpPage, isAuthenticated, navigateToHomePage };
}
