import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const BackButtonStyle = styled(Button)`
  margin-bottom: 10px;
`;

export default function BackButton() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box display="flex" justifyContent="flex-start">
      <BackButtonStyle onClick={handleBack} startIcon={<ArrowBackIcon />}>
        Back
      </BackButtonStyle>
    </Box>
  );
}
