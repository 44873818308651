import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import ArchivedPage from './pages/ArchivedPage';
import AssignmentPage from './pages/AssignmentPage';
import CoursePage from './pages/CoursePage';
import CreatePage from './pages/CreatePage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import SettingsPage from './pages/SettingsPage';
import SubmissionPage from './pages/SubmissionPage';
import TeacherPage from './pages/TeacherPage';

export default function Router({ courses, updateCourses, userid, isLoading, isAuthenticated }) {
  if (isLoading) {
    return <div>Loading</div>; 
  }

  const publicRoutes = [
    { path: 'login', element: <LoginPage /> },
    { path: '/', element: <Navigate to="/login" replace /> },
    { path: '*', element: <Page404 /> },
  ];

  const privateRoutes = [
    {
      path: 'dashboard',
      element: <DashboardLayout courses={courses} />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        {
          path: 'home',
          element: <TeacherPage userid={userid} />,
        },
        {
          path: 'create',
          element: <CreatePage courses={courses} updateCourses={updateCourses} />,
        },
        { path: 'settings', element: <SettingsPage /> },
        {
          path: 'courses/:courseId',
          element: <CoursePage userid={userid} />,
        },
        {
          path: 'submission/:submissionId',
          element: <SubmissionPage userid={userid} />,
        },
        {
          path: 'assignment/:assignmentId',
          element: <AssignmentPage userid={userid} />,
        },
        {
          path: 'archived',
          element: <ArchivedPage userid={userid} />,
        },
        
      ],

    },
    { path: '*', element: <Page404 /> },
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const routes = useRoutes(isAuthenticated ? [...publicRoutes, ...privateRoutes] : publicRoutes);

  return routes;
}