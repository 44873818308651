import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

const AssignmentDialog = ({ openDialog, handleAssignmentNameChange, handleClose, handleCreateAssignment, assignmentName }) => {
    return (
        <Dialog open={openDialog} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Create Assignment</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Assignment Name"
                    value={assignmentName}
                    variant="outlined"
                    onChange={handleAssignmentNameChange}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreateAssignment} disabled={!assignmentName}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AssignmentDialog;