import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
  } from '@mui/material';

const StudentDialog = ({openInviteDialog, handleAddStudent, handleInviteClose, allstudentlist, searchQuery, setSearchQuery, studentlist, addingStudent}) => {
    return (
        <Dialog open={openInviteDialog} onClose={handleInviteClose} maxWidth="sm" fullWidth>
        <DialogTitle>Invite Students</DialogTitle>
        <DialogContent>
          <Typography variant="h6">All Students</Typography>
          <TextField
            label="Search Students"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            fullWidth
            margin="normal"
          />
          {allstudentlist
            .filter((student) => student.name.toLowerCase().includes(searchQuery.toLowerCase()))
            .filter((student) => !studentlist.some((currentStudent) => currentStudent.student_id === student.id))
            .map((student) => (
              <Card
                key={student.id}
                sx={{ mb: 2, cursor: 'pointer', height: '100px' }}
                onClick={() => console.log('Clicked', student.id)}
              >
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">{student.name}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddStudent(student.id)}
                    disabled={addingStudent}
                  >
                    Add
                  </Button>
                </CardContent>
              </Card>
            ))}
        </DialogContent>
      </Dialog>
    )
}

export default StudentDialog;