import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

const LogOutButton = () => {
  const { isAuthenticated, logout } = useAuth0();

  return (
    isAuthenticated && (
      <Button fullWidth size="large" type="submit" variant="contained" onClick={() => logout()}>
        Logout
      </Button>
    )
  );
};

export default LogOutButton;
