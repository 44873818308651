import SvgColor from '../../../components/svg-color';

export const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export default function NavConfig(courses) {
  const navConfig = [
    {
      title: 'home',
      path: '/dashboard/home',
      icon: icon('ic_home'),
    },
    {
      title: 'create',
      path: '/dashboard/create',
      icon: icon('ic_create'),
    },
    ...courses.map((course) => ({
      title: course.name,
      path: `/dashboard/courses/${course.id}`,
      icon: icon('ic_course'),
    })),
    {
      title: 'archive',
      path: '/dashboard/archived',
      icon: icon('ic_archive'),
    },
    // {
    //   title: 'Settings',
    //   path: '/dashboard/settings',
    //   icon: icon('ic_settings'),
    // },
  ];

  return navConfig;
}
