import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react'; 
import { Typography, Container, Stack, Divider, Grid, Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { SubmissionTextViewer, BackButton, QuestionContainer, Video, useSubmission, LearningScore,MemorizationScore,KeywordsMatching } from '../sections/submission';


export default function SubmissionPage({ userid }) {
  const { submissionId } = useParams();
  const { questions, docSnippets, aiInfo,keywordsInfo,learningScore,stylometricScore,answerScore, videoUrl, transcription, studentName,assignmentName,courseName } = useSubmission(submissionId);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const updateSelectedKeywords = (keywordsArr) => {
    setSelectedKeywords(keywordsArr)
  }
  const navigate = useNavigate(); 

  useEffect(() => {
    async function validateSubmission(userid) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/submission/validateSubmission`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: userid, submission_Id: submissionId }),
        });
        if (response.ok) {
          const data = await response.json();
          console.log(data)
          setValidated(data.valid); 
        } else {
          console.log('Failed to fetch submission');
          setValidated(false); 
        }
      } catch (error) {
        console.log(error);
        setValidated(false); 
      } finally {
        setIsLoading(false);
      }
    }

    validateSubmission(userid);
  }, [submissionId, userid]); 

  // Show a loading indicator while fetching the validation result
  if (isLoading) {
    return <div>{}</div>;
  }

  // If not validated, navigate to the 404 page
  if (!validated) {
    navigate('/page404');
    return null; 
  }

  // If validated is true, render the components
  return (
    <>
      <Helmet>
        <title>Submission</title>
      </Helmet>

      <BackButton />

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {studentName} - {courseName} - {assignmentName}
          </Typography>
        </Stack>
        <Grid container spacing={5}>
          <Grid item sm={12} md={6}>
            <SubmissionTextViewer submission={docSnippets} aiInfo={aiInfo} stylometricScore={stylometricScore} selectedKeywords={selectedKeywords} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Box sx={{ mb: 5 }}>{questions.length > 0 && <QuestionContainer questions={questions} transcription={transcription} answerScore={answerScore} />}</Box>
            <MemorizationScore memorizationScore={learningScore}/>
            <LearningScore memorizationScore={learningScore} answerScore={answerScore}/>
            <KeywordsMatching keywordsInfo={keywordsInfo} updateSelectedKeywordsProp={updateSelectedKeywords}/>           
          </Grid>
        </Grid>
        {/* <Divider sx={{ mt: 5, mb: 5 }}/>
        {videoUrl && <Video videoUrl={videoUrl} />} */}
      </Container>
    </>
  );
}
