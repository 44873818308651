export async function fetchSubmission(submissionId) {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/submission/${submissionId}`, {
      method: 'GET',
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    console.log('Failed');
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
}
