import { useAuth0 } from '@auth0/auth0-react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

export default function CreatePage({ courses, updateCourses }) {
  const [open, setOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [courseName, setCourseName] = useState('');
  const { user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchCourses(userid) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/all`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ teacher_id: userid }),
        });
        const data = await response.json();
        updateCourses(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError('Failed to fetch courses.');
        setLoading(false);
      }
    }
    if (user && user.sub) {
      const userid = user.sub.split('|')[1];
      fetchCourses(userid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCourseIndex, setSelectedCourseIndex] = useState(null);

  const handleAddCourse = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCourseName('');
  };

  const handleCreateCourse = async () => {
    if (courseName.trim() !== '') {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            teacher_id: user.sub.split('|')[1],
            teacher_name: user.name,
            name: courseName,
          }),
        });
        const data = await response.json();

        if (response.ok) {
          const newCourse = {
            id: data.courseId,
            name: courseName,
          };

          const newCourses = [...courses, newCourse];
          updateCourses(newCourses); // Update the courses state in App component

          handleClose();
        } else {
          setError(data.message);
        }
      } catch (error) {
        console.log(error);
        setError('Failed to create course.');
      }
    }
  };

  const handleDeleteCourse = () => {
    setDeleteConfirmationOpen(true);
  };

  const confirmDeleteCourse = async () => {
    if (selectedCourseIndex !== null) {
      const courseId = courses[selectedCourseIndex].id;
      try {
        await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/${courseId}`, {
          method: 'DELETE',
        });

        const updatedCourses = [...courses];
        updatedCourses.splice(selectedCourseIndex, 1);
        updateCourses(updatedCourses);
      } catch (error) {
        console.log(error);
        setError('Failed to delete course.');
      }
    }
    handleCloseMenu();
    setDeleteConfirmationOpen(false);
  };

  const handleEditCourse = () => {
    // Implement edit functionality here
    handleCloseMenu();
  };

  const handleOpenMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedCourseIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedCourseIndex(null);
  };

  const handleCourseNameChange = (event) => {
    setCourseName(event.target.value);
  };

  const navigateToCoursePage = (courseId, courseName) => {
    navigate(`/dashboard/courses/${courseId}`);
  };

  return (
    <>
      <Helmet>
        <title>Create</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 5, mt: 5 }}>
          <Typography variant="h4">Create</Typography>

          <Button variant="contained" onClick={handleAddCourse}>
            Add Course
          </Button>
        </Stack>

        {loading ? (
          <Typography variant="h6" align="center" color="textSecondary" sx={{ mt: 10 }}>
            Loading courses...
          </Typography>
        ) : error ? (
          <Typography variant="h6" align="center" color="textSecondary" sx={{ mt: 10 }}>
            Error: {error}
          </Typography>
        ) : courses.length === 0 ? (
          <Typography variant="h6" align="center" color="textSecondary" sx={{ mt: 10 }}>
            No courses yet. Add a course to get started.
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {courses.map((course, index) => (
              <Grid item key={course.id} xs={12} sm={6} md={3}>
                <Card variant="outlined" sx={{ height: 120, width: '100%', borderRadius: '12px' }}>
                  <CardHeader
                    title={course.name}
                    action={
                      <>
                        <IconButton
                          aria-label="options"
                          aria-controls={`course-options-menu-${index}`}
                          aria-haspopup="true"
                          onClick={(event) => handleOpenMenu(event, index)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id={`course-options-menu-${index}`}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && selectedCourseIndex === index}
                          onClose={handleCloseMenu}
                        >
                          <MenuItem onClick={handleEditCourse}>
                            <ListItemIcon>
                              <EditIcon fontSize="small" />
                            </ListItemIcon>
                            Edit
                          </MenuItem>
                          <MenuItem onClick={handleDeleteCourse}>
                            <ListItemIcon>
                              <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            Delete
                          </MenuItem>
                        </Menu>
                      </>
                    }
                  />

                  <CardActions
                    sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: 0, width: '100%', pb: 2 }}
                  >
                    <Button
                      onClick={() => navigateToCoursePage(course.id, course.name)}
                      variant="outlined"
                      size="small"
                    >
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Add Course</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Course Name"
              fullWidth
              variant="outlined"
              size="small"
              value={courseName}
              onChange={handleCourseNameChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleCreateCourse} disabled={!courseName}>
              Create
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to delete this course? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
            <Button onClick={confirmDeleteCourse} color='error' autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
