import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useAuth0 } from '@auth0/auth0-react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

// ----------------------------------------------------------------------

export default function App() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [courses, setCourses] = useState([]);
  const [userid, setUserid] = useState('');

  const updateCourses = (newCourses) => {
    setCourses(newCourses);
  };

  async function fetchCourses(userid) {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ teacher_id: userid }),
      });
      const data = await response.json();
      console.log(data);
      setCourses(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user && user.sub) {
      const userid = user.sub.split('|')[1];
      setUserid(userid);
      fetchCourses(userid);
    }
  }, [user]);

  useEffect(
    () => () => {
      // Cleanup if needed
    },
    [userid]
  );

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router courses={courses} updateCourses={updateCourses} userid={userid} isAuthenticated={isAuthenticated} isLoading={isLoading}/>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
