import {
  Container,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabslist, FirstTab, SecondTab, AssignmentDialog, StudentDialog, TeacherDialog } from '../sections/course'

export default function CoursePage({ userid }) {
  const { courseId } = useParams();
  const [courseName, setcourseName] = useState('');
  const [currentTab, setCurrentTab] = useState(0);
  const [assignmentName, setAssignmentName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [studentlist, setStudentList] = useState([]);
  const [allstudentlist, setAllStudentList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [addingStudent, setAddingStudent] = useState(false);
  const [creatingAssignment, setCreatingAssignment] = useState(false);
  const [teacherList, setTeacherList] = useState([]);
  const [addingTeacher, setAddingTeacher] = useState(false);
  const [allteacherlist, setAllTeacherList] = useState([]);
  const [openInviteDialogTeacher, setOpenInviteDialogTeacher] = useState(false);
  const [validated, setValidated] = useState(false)
  const [isRendering, setIsRendering] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    async function validateCourse(userid) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/validateCourse`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: userid, course_Id: courseId }),
        });
        if (response.ok) {
          const data = await response.json();
          setValidated(data.valid); 
          if (!data.valid) {
            navigate('/page404');
          }
          setcourseName(data.courseName)
        } else {
          console.log('Failed to fetch course');
          setValidated(false); 
        }
      } catch (error) {
        console.log(error);
        setValidated(false); 
      } finally {
        setIsRendering(false);
      }
    }

    validateCourse(userid);
  }, [courseId, userid]); 

  useEffect(() => {
    fetchAssignments(courseId);
    fetchStudents(courseId);
    fetchTeachers(courseId);
  }, [courseId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isRendering) {
    return <div>Loading</div>;
  }

  const inviteStudents = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/student/all`, {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        setAllStudentList(data);
        setOpenInviteDialog(true);
      } else {
        console.log('Failed to fetch students');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddStudent = async (studentId) => {
    try {
      setAddingStudent(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/add_student`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ student_id: studentId, course_id: courseId }),
      });

      if (response.ok) {
        console.log('Student added successfully');
      } else {
        console.log('Failed to add student');
      }
    } catch (error) {
      console.log(error);
    } finally {
      fetchStudents(courseId);
    }
  };

  const deleteStudent = async (courseId, studentId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/${courseId}/delete_student/${studentId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        // Student deleted successfully
        // Refetch students
        fetchStudents(courseId);
      } else {
        // Handle error case
        console.log('Failed to delete student');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTeacher = async (courseId, teacherId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/${courseId}/delete_teacher/${teacherId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        // Student deleted successfully
        // Refetch students
        fetchTeachers(courseId);
      } else {
        // Handle error case
        console.log('Failed to delete student');
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchStudents() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/get_students`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ course_id: courseId }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setStudentList(data);
      } else {
        console.log('Failed to fetch students');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAddingStudent(false);
    }
  }

  async function fetchTeachers() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/get_teachers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ course_id: courseId }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setTeacherList(data);
      } else {
        console.log('Failed to fetch teachers');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAddingTeacher(false);
    }
  }

  const handleAddTeacher = async (teacherId) => {
    try {
      setAddingTeacher(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/course/add_teacher`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ teacher_id: teacherId, course_id: courseId }),
      });

      if (response.ok) {
        console.log('Teacher added successfully');
      } else {
        console.log('Failed to add teachere');
      }
    } catch (error) {
      console.log(error);
    } finally {
      fetchTeachers(courseId);
    }
  };

  const inviteTeachers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/teacher/all`, {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        setAllTeacherList(data);
        setOpenInviteDialogTeacher(true);
      } else {
        console.log('Failed to fetch teachers');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateAssignment = async () => {
    // Validate assignment name
    if (assignmentName.trim() === '') {
      console.log('Assignment name cannot be empty');
      return;
    }

    try {
      setCreatingAssignment(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/assignment/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ course_id: courseId, name: assignmentName }),
      });

      if (response.ok) {
        // Assignment created successfully
        const newAssignment = await response.json();
        setAssignments([...assignments, newAssignment]);
        setAssignmentName(''); // Clear the input field
        setOpenDialog(false); // Close the dialog

        // Refetch assignments
        fetchAssignments(courseId);
        setCreatingAssignment(false);
      } else {
        // Handle error case
        console.log('Failed to create assignment');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAssignment = async (courseId, assignmentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_HOST}/api/assignment/${courseId}/${assignmentId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        // Assignment deleted successfully
        // Refetch assignments
        fetchAssignments(courseId);
      } else {
        // Handle error case
        console.log('Failed to delete assignment');
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchAssignments(courseId) {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/assignment/all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ course_id: courseId, teacher_id: userid }),
      });

      if (response.ok) {
        const data = await response.json();
        setAssignments(data);
      } else {
        console.log('Failed to fetch assignments');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleAssignmentNameChange = (event) => {
    setAssignmentName(event.target.value);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setAssignmentName('');
  };

  const handleInviteClose = () => {
    setOpenInviteDialog(false);
    setSearchQuery('');
  };

  const handleInviteCloseTeacher = () => {
    setOpenInviteDialogTeacher(false);
    setSearchQuery('');
  };

  const FirstTabProps = {
    currentTab, loading, assignments, handleDeleteAssignment, setOpenDialog, creatingAssignment, courseId
  };

  const SecondTabProps = {
    currentTab, teacherList, deleteTeacher, deleteStudent, courseId, userid, inviteStudents, inviteTeachers, studentlist, assignments
  };

  const AssignmentDialogProps = {
    openDialog, handleAssignmentNameChange, handleClose, handleCreateAssignment, assignmentName
  };

  const StudentDialogProps = {
    openInviteDialog, handleAddStudent, handleInviteClose, allstudentlist, searchQuery, setSearchQuery, studentlist, addingStudent
  };

  const TeacherDialogProps = {
    openInviteDialogTeacher, handleInviteCloseTeacher, searchQuery, setSearchQuery, allteacherlist, teacherList, handleAddTeacher, addingTeacher
  }

  return (
    <>
      <Helmet>
        <title>Course Page</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5, mt: 5 }}>
          {courseName} - Course Page
        </Typography>

        <Tabslist currentTab={currentTab} setCurrentTab={setCurrentTab}/>

        <FirstTab {...FirstTabProps}/>

        <SecondTab {...SecondTabProps}/>

      </Container>

      <AssignmentDialog {...AssignmentDialogProps}/>

      <StudentDialog {...StudentDialogProps}/>

      <TeacherDialog {...TeacherDialogProps}/>
    </>
  );
}
