import PropTypes from 'prop-types';
import React, { useState } from 'react';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, FormControl, InputLabel, Select, MenuItem, TextField, Stack, Button, Menu, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
// component
import Iconify from '../../../components/iconify';
import { markarchive, markdeleted, markunarchive } from '../services/teacherservices';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 400,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  filterScore: PropTypes.string,
  onFilterScore: PropTypes.func,
  filterDateRange: PropTypes.array,
  onFilterDateRange: PropTypes.func,
  handleClearAll: PropTypes.func,
  selected: PropTypes.array,
  updateSubmissionList: PropTypes.func,
  clearSelected: PropTypes.func,
  state: PropTypes.number,
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  filterScore,
  onFilterScore,
  filterDateRange,
  onFilterDateRange,
  handleClearAll,
  selected,
  updateSubmissionList,
  clearSelected,
  state,
}) {
  const [scoreFilter, setScoreFilter] = useState(filterScore || '');
  const [startDate, setStartDate] = useState(filterDateRange ? filterDateRange[0] : '');
  const [endDate, setEndDate] = useState(filterDateRange ? filterDateRange[1] : '');
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleClearAllFilters = () => {
    handleClearAll();
    setScoreFilter('');
    setStartDate(''); // Set startDate to null
    setEndDate(''); // Set endDate to null
  };

  const handleScoreFilterChange = (event) => {
    const val = event.target.value;
    setScoreFilter(val);
    onFilterScore(val);
  };

  const handleStartDateChange = (event) => {
    const val = event.target.value;
    setStartDate(val);
    onFilterDateRange([val, endDate]);
  };

  const handleEndDateChange = (event) => {
    const val = event.target.value;
    setEndDate(val);
    onFilterDateRange([startDate, val]);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuSelect = async (option) => {
    if (option === 'Delete') {
      setDeleteConfirmationOpen(true);
    } else {
      await handleMenuAction(option);
    }
  };

  const handleMenuAction = async (option) => {
    if (option === 'Archive') {
      await markarchive(selected);
      updateSubmissionList();
    }
    if (option === 'Unarchive') {
      await markunarchive(selected);
      updateSubmissionList();
    }
    handleMenuClose();
    clearSelected();
  };

  const handleDeleteConfirmationOpen = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteConfirm = async () => {
    await markdeleted(selected);
    updateSubmissionList();
    handleMenuClose();
    clearSelected();
    handleDeleteConfirmationClose();
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Search student, course or assignment..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title="Actions">
          <IconButton onClick={handleMenuOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <Stack spacing={1} direction="row">
            <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
              <InputLabel>Filter Score</InputLabel>
              <Select value={scoreFilter} onChange={handleScoreFilterChange} label="Filter Score">
                <MenuItem value="">All</MenuItem>
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="start-date"
              label="Start Date"
              type="date"
              size="small"
              value={startDate}
              onChange={handleStartDateChange}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              id="end-date"
              label="End Date"
              type="date"
              size="small"
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{ shrink: true }}
            />
            <Button onClick={handleClearAllFilters}>Clear Filters</Button>
          </Stack>
        </>
      )}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {state > 0 ? (
          <MenuItem onClick={() => handleMenuSelect('Archive')}>
            <ListItemIcon>
              <Iconify icon="eva:archive-fill" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Archive" />
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleMenuSelect('Unarchive')}>
            <ListItemIcon>
              <Iconify icon="eva:archive-fill" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Unarchive" />
          </MenuItem>
        )}
        <MenuItem onClick={handleDeleteConfirmationOpen} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Menu>
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteConfirmationClose}
        aria-labelledby="delete-confirmation-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="delete-confirmation-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to delete the selected items?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmationClose}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </StyledRoot>
  );
}
