import React from 'react';
import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';



const Overview = ({ loadingOverview, overview }) => (
    <Box sx={{ width: '100%', margin: '0 auto', maxWidth: '1200px' }}>
        <>
          {loadingOverview ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '200px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {overview.length === 0 ? (
                <Typography variant="h6" align="center" sx={{ mt: 4 }}/>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                  <Card color="#ff7070" count={overview.high} total={overview.total} label="Critical Score" />
                  <Card color="orange" count={overview.med} total={overview.total} label="Medium Score" />
                  <Card color="green" count={overview.low} total={overview.total} label="Low Score" />
                </Box>
              )}
            </>
          )}
        </>
    </Box>
  );

const Card = ({ color, count, total, label }) => {
  const percentage = ((count / total) * 100).toFixed(2);

  const barContainerStyle = {
    width: '100%',
    height: '20px',
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    border: '2px solid white', 
  };

  const barStyle = {
    width: `${percentage}%`,
    height: '100%',
    backgroundColor: color,
    borderRadius: '4px',
    border: '1px solid white', 
  };

  return (
    <Box sx={{ width: '30%', backgroundColor: color, padding: '2rem', borderRadius: '4px'}}>
      <Typography variant="h6" color="black" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
        {label}
      </Typography>
      <Typography variant="h6" color="black" sx={{marginBottom: '1rem'}}>
        {count} / {total} students
      </Typography>
      <Box sx={barContainerStyle}>
        <Box sx={barStyle} />
      </Box>
    </Box>
  );
};

export default Overview;
