import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
  } from '@mui/material';

const TeacherDialog = ({openInviteDialogTeacher, handleInviteCloseTeacher, searchQuery, setSearchQuery, allteacherlist, teacherList, handleAddTeacher, addingTeacher}) => {
    return (
        <Dialog open={openInviteDialogTeacher} onClose={handleInviteCloseTeacher} maxWidth="sm" fullWidth>
        <DialogTitle>Invite Teachers</DialogTitle>
        <DialogContent>
          <Typography variant="h6">All Teachers</Typography>
          <TextField
            label="Search Teachers"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            fullWidth
            margin="normal"
          />
          {allteacherlist
            .filter((teacher) => teacher.name.toLowerCase().includes(searchQuery.toLowerCase()))
            .filter((teacher) => !teacherList.some((currentteacher) => currentteacher.teacher_id === teacher.id))
            .map((teacher) => (
              <Card
                key={teacher.id}
                sx={{ mb: 2, cursor: 'pointer', height: '100px' }}
                onClick={() => console.log('Clicked', teacher.id)}
              >
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">{teacher.name}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddTeacher(teacher.id)}
                    disabled={addingTeacher}
                  >
                    Add
                  </Button>
                </CardContent>
              </Card>
            ))}
        </DialogContent>
      </Dialog>
    )
}

export default TeacherDialog;