import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Snackbar,
    Alert
  } from '@mui/material';
  import AddIcon from '@mui/icons-material/Add';
  import { useState } from 'react';
  
  const SecondTab = ({
    currentTab,
    teacherList,
    deleteTeacher,
    deleteStudent,
    courseId,
    userid,
    inviteStudents,
    inviteTeachers,
    studentlist,
    assignments
  }) => {
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [shareLinkOpen, setShareLinkOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectedItemType, setSelectedItemType] = useState('');
    const [assignment, setAssignment] = useState('');
    const [assignmentLink, setAssignmentLink] = useState('');
    
  
    const handleOpenDeleteConfirmation = (itemId, itemType) => {
      setSelectedItemId(itemId);
      setSelectedItemType(itemType);
      setDeleteConfirmationOpen(true);
    };

    const handleShareLink = (itemId) => {
      setSelectedItemId(itemId);
      setShareLinkOpen(true);
    };
  
    const confirmDeleteItem = () => {
      if (selectedItemType === 'teacher') {
        deleteTeacher(courseId, selectedItemId);
      } else if (selectedItemType === 'student') {
        deleteStudent(courseId, selectedItemId);
      }
      setDeleteConfirmationOpen(false);
    };

    const AssignmentSelection = () => {
      return (
      <FormControl sx={{ marginTop: 1 }}>
        <InputLabel id="assignment-select-label">Assignments</InputLabel>
        <Select
          labelId="assignment-select-label"
          value={assignment}
          label="Assignments"
          onChange={handleAssignmentChange}
        >
          {
            assignments.map((data) => {
              return (
                <MenuItem value={data.id}>{data.name}</MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
      );
    };

    const handleAssignmentChange = (event) => {
      setAssignment(event.target.value);
      const link = `https://captcha.aiseer.co/?assignment_id=${event.target.value}&student_id=${selectedItemId}`;
      setAssignmentLink(link);
      copyLink(link);
    };

    const copyLink = (link) =>{
      navigator.clipboard.writeText(link);
      setAlertOpen(true);
    };
  
    return (
      <>
        {currentTab === 1 && (
          <Box>
            <Box sx={{ width: '75%', margin: '0 auto' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5" sx={{ color: 'primary.main', marginBottom: '0.5rem' }}>
                  Teachers
                </Typography>
                <Box sx={{ marginLeft: 'auto', marginBottom: '0.5rem' }}>
                  <Button variant="contained" startIcon={<AddIcon />} onClick={inviteTeachers}>
                    Invite
                  </Button>
                </Box>
              </Box>
              <Divider sx={{ marginBottom: '1rem' }} />
              {teacherList
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort the teachers by name
                .map((teacher) => (
                  <Card
                    key={teacher.teacher_id}
                    sx={{ mb: 2, cursor: 'pointer', height: '100px' }}
                    onClick={() => console.log('Clicked', teacher.teacher_id)}
                  >
                    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6">{teacher.name}</Typography>
                      {teacher.teacher_id !== userid && (
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => handleOpenDeleteConfirmation(teacher.teacher_id, 'teacher')}
                        >
                          Delete
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                ))}
            </Box>
            <Box sx={{ width: '75%', margin: '0 auto' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5" sx={{ color: 'primary.main', marginBottom: '0.5rem' }}>
                  Students
                </Typography>
                <Box sx={{ marginLeft: 'auto', marginBottom: '0.5rem' }}>
                  <Button variant="contained" startIcon={<AddIcon />} onClick={inviteStudents}>
                    Invite
                  </Button>
                </Box>
              </Box>
              <Divider sx={{ marginBottom: '1rem' }} />
              {studentlist.map((student) => (
                <Card
                  key={student.student_id}
                  sx={{ mb: 2, cursor: 'pointer', height: '100px' }}
                  onClick={() => console.log('Clicked', student.student_id)}
                >
                  <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">{student.name}</Typography>
                    <Box sx={{ display: 'flex', flexDirection:"column", gap:"5px" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleShareLink(student.student_id)}
                      >
                        Send Assignment
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => handleOpenDeleteConfirmation(student.student_id, 'student')}
                      >
                        Delete
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        )}

        <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }} open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
          <Alert onClose={() => setAlertOpen(false)} severity="success" sx={{ width: '100%' }}>
            Link Copied!
          </Alert>
        </Snackbar>
        <Dialog open={shareLinkOpen} onClose={() => setShareLinkOpen(false)} fullWidth>
          <DialogTitle>Send Assignment Links</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection:"column", gap:"5px" }}>
            <AssignmentSelection/>
            <TextField
              value={assignmentLink}
              InputProps={{
                readOnly: true,
              }}
              onClick={() => { copyLink(); }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShareLinkOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
  
        <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <Typography variant="body1">Are you sure you want to delete this {selectedItemType}?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
            <Button onClick={confirmDeleteItem} color='error' autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  export default SecondTab;
  