import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FirstTab = ({
  currentTab,
  loading,
  assignments,
  handleDeleteAssignment,
  setOpenDialog,
  creatingAssignment,
  courseId,
}) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);

  const handleOpenDeleteConfirmation = (assignmentId) => {
    setSelectedAssignmentId(assignmentId);
    setDeleteConfirmationOpen(true);
  };

  const confirmDeleteAssignment = () => {
    handleDeleteAssignment(courseId, selectedAssignmentId);
    setDeleteConfirmationOpen(false);
  };

  const navigate = useNavigate();

  const handleView = (assignmentId, assignmentName) => {
    const link = assignmentId;
    navigate(`/dashboard/assignment/${link}`);
  };

  return (
    <>
      {currentTab === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '50%', margin: '0 auto' }}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {assignments.map((assignment) => (
                  <Card
                    key={assignment.id}
                    sx={{ mb: 2, cursor: 'pointer', height: '100px' }}
                    onClick={() => handleView(assignment.id, assignment.name)}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="h6">{assignment.name}</Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); 
                          handleOpenDeleteConfirmation(assignment.id);
                        }}
                      >
                        Delete
                      </Button>
                    </CardContent>
                  </Card>
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                  <Button
                    variant="contained"
                    onClick={() => setOpenDialog(true)}
                    disabled={creatingAssignment}
                  >
                    Create Assignment
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Delete Assignment</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to delete this assignment?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={confirmDeleteAssignment} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FirstTab;
