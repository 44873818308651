import React from 'react';
import { Box, Typography } from '@mui/material';

const scoreDesc = {
  0: "NO UNDERSTANDING",
  1: "SOME UNDERSTANDING",
  2: "GOOD UNDERSTANDING",
};

const QuestionContainer = ({ questions, transcription,answerScore }) => (
  <Box>
    {questions.map((question, index) => {
      const { start: questionStart, end: questionEnd } = question;
      const transcriptText = question.answer;
      // const matchedTranscriptions = transcription.filter(
      //   (transcript) => transcript.start >= questionStart && transcript.end <= questionEnd
      // );

      // let concatenatedTranscript = '';
      // matchedTranscriptions.forEach((transcript) => {
      //   concatenatedTranscript += `${transcript.text} `;
      // });

      return (
        <Box key={index}>
          <Typography variant="h5" gutterBottom>
            Question {index + 1}:
          </Typography>
          <Typography variant="body1" gutterBottom>{question.question}</Typography>
          {transcriptText && (
            <div>
              <Typography variant="body1" gutterBottom>
              <strong>Answer:</strong> {transcriptText}
              </Typography>
              <Typography variant="body1" gutterBottom>
              <strong>Assessment:</strong> {question.assessment}
              </Typography>
            </div>
          )}
        </Box>
      );
    })}
    <Typography variant="h5">
      <strong>Overall Assessment:</strong> <span style={{color: answerScore === 2 ? "green" : answerScore === 1 ? "#de9104fc" : "red"}}>{scoreDesc[answerScore]}</span>
    </Typography>
  </Box>
);

export default QuestionContainer;
