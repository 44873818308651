import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { Worker } from '@react-pdf-viewer/core';
import workerSrc from 'pdfjs-dist/build/pdf.worker.entry';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import packageJson from '../../../../package.json';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

const PDFViewer = ({ documentLink, documentType }) => {
    const [numPages, setNumPages] = useState(null);

    pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

    useEffect(() => {
        // Dynamically set the workerSrc when the component mounts
        pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
    }, []);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    // Only supports pdf preview for now
    const renderDocument = () => {
        let document = <></>;

        if (documentType === 'pdf') {
            document = <div style={{ width: '100%', height: '400px', overflow: 'auto' }}>
                            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
                                <Document file={documentLink} onLoadSuccess={onDocumentLoadSuccess}>
                                    {Array.from(new Array(numPages), (el, index) => (
                                        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={800} />
                                    ))}
                                </Document>
                            </Worker>
                        </div>;
        } else if (documentType === 'docx') {
            const docs = [{ uri: documentLink, fileType: documentType }];
            document = <div style={{ width: '100%', height: '500px', overflow: 'auto' }}>
                            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
                        </div>;
        }
        
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {document}
                <Button href={documentLink} variant="contained" style={{ marginTop: '16px' }}>
                    Download File
                </Button>
            </div>
        );
    }

    return renderDocument(numPages);
};

export default PDFViewer;