import { Button, Container, Divider, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoginButton from './LoginButton';
import LogOutButton from './LogOutButton';

export default function StyledContent({ navigateToSignUpPage, navigateToHomePage, isAuthenticated }) {
  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

  return (
    <Container maxWidth="sm">
      <StyledContent>
        <Typography variant="h4" gutterBottom>
          Sign in to Sincerely
        </Typography>

        <Typography variant="body2">
          Don’t have an account? {''}
          <Link onClick={navigateToSignUpPage} variant="subtitle2">
            Get started
          </Link>
        </Typography>

        <Divider sx={{ my: 3 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            OR
          </Typography>
        </Divider>
        {isAuthenticated ? (
          <Button onClick={navigateToHomePage}>Go to HomePage</Button>
        ) : (
          <>
            <LoginButton />
            <LogOutButton />
          </>
        )}
      </StyledContent>
    </Container>
  );
}
