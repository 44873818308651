import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Container,
    Stack,
    Typography,
    Box
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { BackButton } from '../sections/submission';
import { SubmissionPlot } from '../components/tableview';
import { fetchAssignmentSubmission, fetchOverview } from '../sections/teacher/services/teacherservices';
import Overview from '../sections/course/components/Overview';

export default function AssignmentPage({ userid }) {
    const { assignmentId } = useParams()
    const [assignmentName, setAssignmentName] = useState('')
    const [overview, setOverview] = useState([]);
    const [loadingOverview, setLoadingOverview] = useState(true);

    const [selected, setSelected] = useState([]);
    const updateSelected = (evt) => {
        console.log(evt)
        setSelected([evt.target.value])
    }

    useEffect(() => {
        const fetchData = async () => {
          const data = await fetchOverview(assignmentId, setLoadingOverview);
          setOverview(data.overview)
          setAssignmentName(data.name)
        };
        try {
            fetchData();
        } catch (e){
            console.log(e)
        }
      }, [userid, assignmentId]);      

    return (
        <>
            <Helmet>
                <title> {assignmentName}</title>
            </Helmet>
            <Container maxWidth="xl">
            <BackButton/>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 5, mt: 5 }}>
                    <Typography variant="h4">{assignmentName}</Typography>
                </Stack>
                <Box marginBottom={3}>
                <Overview loadingOverview={loadingOverview} overview={overview}/>
                </Box>
                <SubmissionPlot userid={userid} fetchSubmissionData={fetchAssignmentSubmission} assignmentId={assignmentId} state={2} overview={overview} setOverview={setOverview} setLoadingOverview={setLoadingOverview} selected={selected} updateSelected={updateSelected} 
                />
            </Container>
        </>
    );
}
