import {
    Box,
    Tab,
    Tabs,
} from '@mui/material';

const Tabslist = ({ currentTab, setCurrentTab }) => {
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Tabs value={currentTab} onChange={handleTabChange} sx={{ mb: 5 }}>
                {/* <Tab label="Overview" sx={{ minWidth: '200px' }} /> */}
                <Tab label="Assignments" sx={{ minWidth: '200px' }} />
                <Tab label="People" sx={{ minWidth: '200px' }} />
            </Tabs>
        </Box>
    )
}

export default Tabslist;