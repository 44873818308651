import { Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function SettingsPage() {
  return (
    <>
      <Helmet>
        <title> Settings </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>
        </Stack>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src="/assets/images/seer.png" alt="About" style={{ width: '50%', height: 'auto' }} />
        </div>
      </Container>
    </>
  );
}
