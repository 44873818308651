import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import './styles/Keywords.css';
import Mark from "mark.js";

const SubmissionTextViewer = ({ submission, aiInfo, stylometricScore,selectedKeywords }) => {
    const [highlightedText, setHighlightedText] = useState([]);
    const [keywordsState, setKeywordsState] = useState([]);
    const [classify, setClassify] = useState('');
    const [author, setAuthor] = useState('');
    const markInstance = new Mark(document.querySelector("#search-node"));

    useEffect(() => {
        if(Object.keys(aiInfo).length > 0){
            setClassify(aiInfo.classify)
            const detectedLines = aiInfo.lines.reduce((filtered, line) => {
                if (line[2] !== undefined) {
                    const intProbability = parseInt(line[2],10)
                     if(intProbability > 40){
                        filtered.push([line[0],intProbability]);
                     }
                }else if (line[1] < 50) { // for old api output, deprecated
                   filtered.push(line[0]);
                }
                return filtered;
              }, []);
            setHighlightedText(detectedLines);
        }
    }, [aiInfo]);

    useEffect(() => {
        if(highlightedText.length > 0){
            highlightedText.forEach((value) => {
                // for old api output, deprecated
                if (typeof value === 'string'){
                    markInstance.mark(value, {accuracy:"exactly",separateWordSearch:false});
                }
                // for new api output
                if(Array.isArray(value)){
                    if(value[1] >= 70){
                        markInstance.mark(value[0], {accuracy:"exactly",separateWordSearch:false, className:"red_bg"});
                    }else{
                        markInstance.mark(value[0], {accuracy:"exactly",separateWordSearch:false});
                    }
                }
            });
        }
    }, [highlightedText]);

    useEffect(() => {
        const cleanupDynamicStyles = () => {
            const existingStyleElement = document.getElementById('dynamic-keyword-styles');
            if (existingStyleElement) {
                existingStyleElement.remove();
            }
        };

        const createDynamicStyles = (keywords) => {
            cleanupDynamicStyles(); 

            const styleElement = document.createElement('style');
            styleElement.type = 'text/css';
            styleElement.id = 'dynamic-keyword-styles'; 

            // Construct CSS rules dynamically
            let styles = '';
            keywords.forEach((keyword) => {
                const className = `highlight-keyword-${keyword.key}`;
                styles += `
                    .${className} {
                        background-color: ${keyword.color} !important; /* Override background color */
                        padding: 0.1em;
                        color: #000 !important; /* Override text color */
                        border-radius: 3px; /* Optional for rounded corners */
                    }
                `;
            });

            styleElement.textContent = styles;
            document.head.appendChild(styleElement);
        };

        if (selectedKeywords && selectedKeywords.length > 0) {
            createDynamicStyles(selectedKeywords);
        }

        return () => {
            cleanupDynamicStyles();
        };
    }, [selectedKeywords]);

    useEffect(() => {
        const cleanupKeywords = () => {
          // Remove previous styles
          const existingStyleElement = document.getElementById('dynamic-keyword-styles');
          if (existingStyleElement) {
            existingStyleElement.remove();
          }
          keywordsState.forEach(kw => {
            markInstance.unmark({ className: `highlight-keyword-${kw.key}` });
          });
        };
      
        cleanupKeywords(); 
      
        const createDynamicStyles = (keywords) => {
          const styleElement = document.createElement('style');
          styleElement.type = 'text/css';
          styleElement.id = 'dynamic-keyword-styles'; 
          let styles = '';
      
          keywords.forEach((keyword) => {
            const className = `highlight-keyword-${keyword.key}`;
            styles += `
              .${className} {
                background-color: ${keyword.color} !important;
                padding: 0.1em;
                color: #000 !important;
                border-radius: 3px;
              }
            `;
          });
      
          styleElement.textContent = styles;
          document.head.appendChild(styleElement);
        };
      
        if (selectedKeywords && selectedKeywords.length > 0) {
          createDynamicStyles(selectedKeywords);
      
          selectedKeywords.forEach(keyword => {
            const className = `highlight-keyword-${keyword.key}`;
            markInstance.mark(keyword.label, {
              accuracy: "exactly",
              separateWordSearch: false,
              className 
            });
          });
        }
      
        setKeywordsState(selectedKeywords);
      
        return () => {
          cleanupKeywords();
        };
      }, [selectedKeywords, markInstance]);
      

    useEffect(() => {
        if(Object.keys(stylometricScore).length > 0){
            setAuthor(stylometricScore.author)
        }
    }, [stylometricScore]);

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Submission:
            </Typography>
            <Box sx={{ border: 1, padding: 2, borderRadius: 1 }}>
                <div id="search-node">
                    {submission}
                </div>
            </Box>
            {/* Update the descriptions below as per your dynamic color coding */}
            <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">Highlights show different aspects based on the selected keywords.</Typography>
            <Typography variant="h6" marginTop={3}>
                This submission is most likely to be written by AI with a <span style={{ color: parseInt(classify, 10) > 70 ? "red" : parseInt(classify, 10) > 50 ? "orange" : "green" }}>{classify}</span> confidence
            </Typography>
            <Typography variant="h6" marginTop={3}>
                This submission is most likely to be written in a <span style={{ color: author === "same" ? "green" : "red" }}>{author}</span> style compared to the answer given by the student
            </Typography>
        </Box>
    );
};

export default SubmissionTextViewer;